export enum COVER_TYPE {
	DEATH = 'DTH',
	TOTAL_PERMANENT_DISABILITY = 'TPD',
	INCOME_PROTECTION = 'IP',
}

export enum COVER_STATUS {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	PENDING_EMPLOYER_CONTRIBUTION = 'Pending contribution',
	LAPSED = 'Lapsed',
}

export enum INSURANCE_PLAN {
	AUSTRALIAN_SUPER_PLAN = 'AustralianSuper Plan',
	PUBLIC_SECTOR_DIVISION = 'Public Sector Division',
	AUSTRALIAN_SUPER_SELECT = 'AustralianSuper Select',
	PERSONAL_PLAN = 'Personal Plan',
	SUPER_OPTIONS = 'Super Options',
	GHD_SUPERANNUATION_PLAN = 'GHD Superannuation Plan',
}

export enum INSURANCE_DESIGN {
	PERCENTAGE_OF_SALARY = 'Percentage of salary',
	MULTIPLE_OF_SALARY = 'Multiple of salary',
	AGED_BASED = 'Age-based',
	AGED_BASED_FIXED = 'Age-based + Fixed',
	FIXED = 'Fixed',
	UNKNOWN_FORMULA = 'Unknown Formula',
	PERCENTAGE_OF_SALARY_TO_65 = 'Percentage of salary to age 65',
}

export enum INSURANCE_FORMULA {
	PERCENTAGE_OF_SALARY = 'Salary %',
	MULTIPLE_OF_SALARY = 'Salary Multiple',
	AGED_BASED = 'AgeBased',
	FIXED = 'Fixed',
	PERCENTAGE_OF_SALARY_TO_65 = 'Salary % to Age 65',
	PERCENTAGE_OF_SALARY_TO_YRS = 'Salary % to YRS',
}

export enum INSURANCE_CONDITIONS {
	EXPIRY_AGE = 'expiryage',
	WAITING_PERIOD = 'waitingperiod',
	BENEFIT_PERIOD = 'benefitperiod',
	PERIOD_OF_FUTURE_SERVICE = 'periodoffutureservice',
}

export const STDIP_ABSE = 'STDIP_ABSE';
export const DTH_15 = 'DTH_15';
export const STANDARD_INSURANCE_TYPE = 'STD';

import { lighten, darken } from 'polished';

// -------------------------------------
// Layout

// Common colors
export const brandWhite = '#fff';
export const brandBlack = '#000';
export const borderGrey = 'rgba(0, 0, 0, 0.1)';
export const bgCardColor = '#eef2f6';
export const bpayLogo = '#183168';

export const mobileLayoutPadding = '8px';
export const desktopLayoutPadding = '32px';

export const mobileLayoutWidth = '100%';
export const desktopLayoutWidth = '1336px';

// a.k.a. 'gap' between Cards
export const mobileModuleSpacing = '24px';
export const desktopModuleSpacing = '32px';

// a.k.a. top/bottom margins spacing the main placeholder
// apart from the top/bottom placeholders (header/footer etc)
export const pageTemplateMargin = '52px 0';
export const mobilePageTemplateMargin = '32px 0';

// -------------------------------------
// -------------------------------------
// Forms

export const formCtrlHolderSpacing = '20px';

// -------------------------------------
// TODO refactor to infer from theme.variables

export const baseTransition = '300ms';

// -------------------------------------
// Form field

// $formfield-font-size-mobile: 16px; // iOS zooms on label text any lower than 16px
export const formfieldFontSize = '16px';
export const formfieldHelpTextFontSize = '14px';
export const formfieldLabelOptionalColor = '#666';
export const formfieldTextInset = '15px';
export const formfieldLineHeight = 1.4;
export const formfieldColor = '#414042';
export const formSelectIndicatorColor = '#15181B';
// $formfield-background: $brand-white;
export const formfieldBorderColor = 'lightgrey';
export const formfieldBorderColorFocus = '#260046'; // TODO get from theme
export const formfieldPlaceholderColor = lighten(0.2, formfieldColor);
export const formfieldBorderRadius = '4px';
// $formfield-addon-background: $brand-grey-light;

// -------------------------------------
// Form label

export const formlabelColor = '#414042';
export const formlabelFontSize = formfieldFontSize;
// $formlabel-line-height: formfieldLineHeight;
// $formlabel-required-color: $brand-grey-dark;

// -------------------------------------
// Table Padding

export const tablePadding = '10px';

// -------------------------------------
// Success/Error states

export const formfieldErrorColor = '#B00116';
export const formfieldErrorBackground = lighten(0.55, formfieldErrorColor);
// $formfield-error-hover: darken($formfield-error-background, 5%);
export const formfieldErrorBorderColor = formfieldErrorColor;
export const formfieldErrorMessageFontSize = '14px';
export const formfieldErrorMessageColor = formfieldErrorColor;
export const formfieldOnHoverBorderColor = '#260046';
// $formfield-error-message-background: #CF0000;

// $formfield-success-color: #57B175;
// $formfield-success-background: $formfield-background;
// $formfield-success-border-color: formFieldErrorColor;

export const componentErrortBackground = '#f5efd9';
export const componentErrorTitleFontSize = '18px';

// -------------------------------------
// Disabled

// $formfield-disabled-color: $brand-white;
export const formfieldDisabledBackground = '#f1f1f1';
// $formfield-disabled-border-color: formfieldBorderColor;

// -------------------------------------
// Error summary

export const formSummaryTextColor = formfieldErrorColor;
export const formSummaryBackgroundColor = formfieldErrorBackground;
export const formSummaryBorderColor = formfieldErrorBorderColor;

export const radioCheckboxDefault = formfieldBorderColor;
export const radioCheckboxDisabled = darken(0.2, formfieldBorderColor);

// -------------------------------------
// Radio buttons

export const radioButtonBorder = formfieldBorderColor;

// -------------------------------------
// Sitecore Experience Editor
export const noticeBadge = {
	primary: '#175E17',
	secondary: '#FFFFFF',
};

// -------------------------------------
// Z-index

export const zIndexBase = {
	top: 200,
	middle: 100,
	bottom: 0,
};

// -------------------------------------
// onboarding font sizes

export const onboardHeadingFontSize = {
	desktop: '44px',
	mobile: '28px',
	givenNameMobile: '32px',
};
export const onboardModalFontSize = {
	modalHeaderFont: '24px',
	listFont: '12px',
};
export const onboardUnfundedFontSize = {
	listHeaderFontDesktop: '24px',
	listHeaderFontMobile: '20px',
};
export const tourHighlightsFontSize = {
	title: '18px',
	description: '14px',
	stepsCounter: '12px',
};
export const dashboardProfileSetupActions = {
	// Hex values
	checkCircleColor: '#828282',
	// Font sizes
	largestFontSize: '24px',
	largeFontSize: '18px',
	mediumFontSize: '16px',
	smallFontSize: '12px',
};

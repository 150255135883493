import dateFnsFormat from 'date-fns/format';
import { get as safeGet, isEmpty } from 'lodash';
import moment, { DurationInputArg2, DurationInputArg1 } from 'moment';
import { formatInTimeZone, format } from 'date-fns-tz';
import { isWithinInterval } from 'date-fns';

import { FY_END_DATE } from 'core/constants';

export const DEFAULT_TIMEZONE = 'Australia/Sydney';

export enum DATE_FORMATS {
	DEFAULT = 'dd/MM/yyyy',
	CONDENSED = 'dd/MM/yy',
	/** E.g. 25 June 2021 */
	DAY_MONTH_NAME_YEAR = 'DD MMMM YYYY',
	/** E.g. 25 Jun 2021 */
	DATE_SHORT_MONTH = 'DD MMM YYYY',
	/** E.g 1 June 2021, 1:00PM */
	DATE_WITH_TIME = 'D MMMM YYYY, h:mmA',
	/** E.g 7 June 2021 */
	/** Only used if using date-fns - full month name */
	DAY_MONTH_NAME_YEAR_FNS = 'd MMMM yyyy',
	/** E.g 7 Jun 2021 */
	/** Only used if using date-fns - short month name */
	DAY_SHORT_MONTH_NAME_YEAR_FNS = 'd MMM yyyy',
}

const UTC_DATE_PATTERN = 'YYYY-MM-DDTHH:mm:ssZ';

/**
 * Format date from the data
 *
 * @param {date | Date} date - string.
 * @param {format} format    - Type of format string.
 */
export const convertDate = (date: string | Date, format: string) => {
	const utc = moment(date, UTC_DATE_PATTERN, true);
	const isUtc = utc.isValid();

	return isUtc
		? moment(date)
				.utc()
				.format(format)
		: moment(date).format(format);
};

/**
 * Format non UTC date
 *
 * @param {date | Date} date - string.
 * @param {format} dateFormat    - Type of format string.
 */
export const convertNonUtcDate = (date: string | Date, dateFormat: string) => {
	return formatInTimeZone(new Date(date), 'Australia/Sydney', dateFormat);
};

/**
 * @description Convert timezone, and format date as per business rules
 * @param {string | Date} date - Date to be converted
 * @param {string} dateFormat - Format of date'.
 * @param {string} timeFormat - Format of time.
 * @param {enums} displayType - Handles two different display.
 * @param {string} timeZone - timeZone value, if timezone is not needed - default value to pass is N/A
 */
export const convertTimezoneWithCustomDateFormat = (
	date: string | Date,
	dateFormat: string,
	timeFormat: string,
	displayType: 'full-with-comma' | 'partial-no-comma',
	timeZone?: string,
) => {
	if (
		typeof date !== 'string' ||
		typeof dateFormat !== 'string' ||
		typeof timeFormat !== 'string' ||
		typeof displayType !== 'string'
	) {
		return 'Invalid date';
	}

	const defaultTimeZone = 'Australia/Sydney';
	const formattedDate = formatInTimeZone(
		new Date(date),
		defaultTimeZone,
		dateFormat,
	);
	const formattedTimeWithTZ = formatInTimeZone(
		new Date(date),
		defaultTimeZone,
		timeFormat,
	).toLowerCase();
	const formattedTime =
		timeZone === 'N/A'
			? format(new Date(date), timeFormat)
			: formattedTimeWithTZ;

	return displayType === 'partial-no-comma'
		? `${formattedDate} ${formattedTime}`
		: `${formattedDate}, ${formattedTime}`;
};

export function formatDate(
	timeStamp: string,
	dateFormat: string = DATE_FORMATS.DEFAULT,
) {
	const date = new Date(timeStamp);

	// Since we only support two date formats at the moment, check if it's the condensed variant, otherwise return the
	// default no matter what it is. The only supported formats are those in the enums
	return dateFormat.toLowerCase().replace('mm', 'MM') === DATE_FORMATS.CONDENSED
		? dateFnsFormat(date, DATE_FORMATS.CONDENSED)
		: dateFnsFormat(date, DATE_FORMATS.DEFAULT);
}

export function getDateFormatFromSitecoreContext(
	sitecoreSiteSettings: any,
): string {
	const dateFormatSettingsObject = sitecoreSiteSettings.find(
		(setting: { dateFormat: string }) => setting.dateFormat,
	);

	return safeGet(
		dateFormatSettingsObject,
		'dateFormat[0].key',
		DATE_FORMATS.DEFAULT,
	);
}

/**
 * Get the closest Financial Year date.
 *
 * @param {Date} date                           - the given date
 * @param {boolean=true} shouldGetPrecedingFY - should whether calculate the closest year by look back to the preceding year
 * @return {string} the closest FY end date string
 * @example
 * ("2019-07-01", true) => "2019-06-30"
 * ("2019-07-01", false) => "2020-06-30"
 */
const getClosestFYEndDate = (date: Date, shouldGetPrecedingFY = true) => {
	const parsedDate = moment(date);
	const currentYear = parsedDate.year();
	const fyEnd = moment(`${currentYear}-${FY_END_DATE}`).endOf('day');
	// Check is the given date passed the it's years FY end date
	const isAfterFYEnd = parsedDate.isAfter(fyEnd);

	let revisedYear = currentYear;

	// Decrement the year to get preceding year if the given date is before the FY end date of its current year
	if (shouldGetPrecedingFY && !isAfterFYEnd) revisedYear -= 1;
	// Increment the year to get next year if the given date is After the FY end date of its current year
	if (!shouldGetPrecedingFY && isAfterFYEnd) revisedYear += 1;

	return { year: revisedYear, fyEndDate: `${revisedYear}-${FY_END_DATE}` };
};

interface IGetFYEndDates {
	endDate: Date;
	numberOfYears?: number;
	startDate?: Date;
}

/**
 * Get a list of Financial Year dates.
 *
 * @param {Date} param0.endDate          - the end date of the period of FYs
 * @param {number=} param0.numberOfYears - if providing, get the given number of FY from the endDate
 * @param {Date=} param0.endDate         - if providing, get FY between startDate and the endDate
 * @returns {string[]} array of FY end dates
 */
export const getFYEndDates = ({
	endDate,
	numberOfYears,
	startDate,
}: IGetFYEndDates): string[] => {
	const { year: endFYYear, fyEndDate: endFYDate } = getClosestFYEndDate(
		endDate,
	);
	const shouldGenerateDatesByInterval = typeof numberOfYears === 'number';

	// Priorities to use numberOfYears to get FY end dates
	if (shouldGenerateDatesByInterval) {
		return Array.from(
			Array(numberOfYears),
			(_, i) => `${endFYYear - i}-${FY_END_DATE}`,
		);
	}

	// If starDate present, use it to get FY end dates
	if (startDate) {
		if (startDate > endDate) {
			throw new Error(
				'Please check the startDate to ensure it is before the endDate',
			);
		}

		const { year: startFYYear } = getClosestFYEndDate(startDate, false);

		if (startFYYear < endFYYear) {
			// Returns all FY end dates between the given startFYYear and endFYYear
			return Array.from(
				Array(endFYYear - startFYYear + 1),
				(_, i) => `${endFYYear - i}-${FY_END_DATE}`,
			);
		} else if (startFYYear > endFYYear) {
			// No valid FY years between the given period
			return [];
		}
	}

	return [endFYDate];
};

/**
 * Check if the a date is within a duration.
 *
 * @param date	 - the date that is being checked
 * @param target - the end date of the duration
 * @param amount - amount of the duration unit
 * @param unit 	 - unit of duration, e.g. 'day', 'month'
 */
export const checkWithinDuration = (
	date: Date,
	target: Date,
	amount: DurationInputArg1,
	unit: DurationInputArg2 = 'month',
) => {
	const checkedDate = moment(date);
	// end time of the duration should be the end of the target day
	const endOfDuration = moment(target).endOf('day');
	// start time of the duration by move the target day ahead by the given number of month of the duration
	const startOfDuration = moment(target)
		.subtract(amount, unit)
		.startOf('day');

	return (
		checkedDate.isSameOrAfter(startOfDuration) &&
		checkedDate.isSameOrBefore(endOfDuration)
	);
};

/**
 * Get Current Financial Dates
 * @returns { from: string; to: string; }
 */
export const getCurrentFinancialDates = () => {
	const today = new Date();

	return today.getMonth() + 1 < 7
		? {
				from: `${today.getFullYear() - 1}-07-01`,
				to: `${today.getFullYear()}-06-30`,
		  }
		: {
				from: `${today.getFullYear()}-07-01`,
				to: `${today.getFullYear() + 1}-06-30`,
		  };
};

/**
 * Check if the server date time is within the publish and unpublish date period.
 *
 * @param date	 - server date time from sitecore context.
 * @param publishDate - start date on when to show specific component
 * @param unpublishDate - end date on when to show specific component
 */
export const checkWithinPublishPeriod = (
	date: Date,
	publishDate: Date,
	unpublishDate: Date,
) => {
	const serverDateTime = moment(date);
	const whenToPublish = moment(publishDate);
	const whenToUnpublish = moment(unpublishDate);

	return serverDateTime.isBetween(whenToPublish, whenToUnpublish, null, '[]');
};

/**
 * @description - Dedicated for withdrawals components. Converts an input string date into 'YYYY-MM-DD' format
 * @param stringDate - "August 28, 1960"
 *
 * @example withdrawalsDateparser(September 18, 1964)
 * @returns {string} "1964-09-18"
 */
export const withdrawalsDateparser = (stringDate: string) => {
	const date = new Date(stringDate);
	const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
	let month: string | number = date.getMonth() + 1;
	month = month > 9 ? month : `0${month}`;
	const year = date.getFullYear();
	// format your date as you expect
	const dateFormat = `${year}-${month}-${day}`;

	return dateFormat;
};

/**
 * Check if the current date time is within the start and end date of restriction period.
 *
 * @param restrictionStartDate - start date of the restriction period
 * @param restrictionEndDate - end date of the restriction period
 */
export const checkWithinRestrictionPeriod = (
	restrictionStartDate: string,
	restrictionEndDate: string,
) => {
	let isRestricted = true;

	if (isEmpty(restrictionStartDate) || isEmpty(restrictionEndDate)) {
		return { isRestricted: false, formattedRestrictionEnd: '' };
	}

	const restrictionPeriod = {
		startTime: new Date(restrictionStartDate),
		endTime: new Date(restrictionEndDate),
	};

	const today = new Date();
	const nowInUsersTimezone = new Date(
		today.toLocaleString('en-US', { timeZone: DEFAULT_TIMEZONE }),
	);

	const restrictionStart = new Date(
		restrictionPeriod.startTime.toLocaleString('en-US', {
			timeZone: DEFAULT_TIMEZONE,
		}),
	);
	const restrictionEnd = new Date(
		restrictionPeriod.endTime.toLocaleString('en-US', {
			timeZone: DEFAULT_TIMEZONE,
		}),
	);

	const formattedRestrictionEnd = convertTimezoneWithCustomDateFormat(
		restrictionEndDate,
		'd MMM',
		'hh:mm a',
		'full-with-comma',
		'Australia/Sydney',
	);

	if (
		isWithinInterval(nowInUsersTimezone, {
			start: restrictionStart,
			end: restrictionEnd,
		})
	) {
		isRestricted = true;
	} else {
		isRestricted = false;
	}

	return { isRestricted, formattedRestrictionEnd };
};

/**
 *  format date ( e.g., 2024-12-19 ) to  (19 Dec 2024)
 */
export const formatFullDate = (dateString: string): string => {
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.toLocaleString('en-US', { month: 'short' }); // e.g., "Dec"
	const year = date.getFullYear();

	return `${day} ${month} ${year}`;
};

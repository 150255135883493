/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/camelcase */
import { usePostMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import { useUpdateProfileActions } from 'core/api/client/MemberApi';

export default smartMockApi({
	useUpdateProfileActions: {
		live: useUpdateProfileActions,
		mock: () =>
			usePostMock({
				payload: {
					data: {
						profile_actions: {
							review_your_personal_details: true,
						},
					},
				},
			}),
	},
});

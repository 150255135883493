/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VAL_00000001
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

export interface MemberData {
	data?: Member;
}

export interface Member {
	party_type_id: string;
	customer_reference_number: string;
	/**
	 * Title of the member contact
	 */
	salutation: string;
	/**
	 * First name of the member contact
	 */
	first_name: string;
	/**
	 * Last name of the member contact
	 */
	last_name: string;
	/**
	 * Birth date of the member contact
	 */
	birth_date: string;
	/**
	 * Date of death of the member contact
	 */
	date_of_death: string;
	/**
	 * age of the member contact
	 */
	age: number;
	/**
	 * gender of the member contact
	 */
	gender: string;
	/**
	 * email address of the member contact
	 */
	email_address: string;
	/**
	 * status of the member contact (Active, Inactive)
	 */
	member_status: string;
	/**
	 * indicator if first login in web portal
	 */
	first_web_portal_login?: boolean;
	/**
	 * indicator of stop payment
	 */
	stop_payment_indicator?: string;
	/**
	 * classification id of the member
	 */
	classification_id: string;
	/**
	 * phone numbers of the member contact
	 */
	phone_numbers: PhoneNumber[];
	/**
	 * addresses of the member contact
	 */
	addresses: Address[];
	/**
	 * accounts of the member contact
	 */
	accounts: MemberAccount[];
	profile_actions?: ProfileActionsResponse;
}

export interface MemberAccount {
	account_number?: string;
	/**
	 * Accumulation or Pension
	 */
	product_name?: string;
	/**
	 * Status of account (Active, Inactive, In-Active, Provisional, Holding, Pending)
	 */
	account_status?: string;
	/**
	 * Date when the member joined
	 */
	joined_date?: string;
	/**
	 * Account Classification
	 */
	product_classification?: string;
	/**
	 * Application type (Direct, Deemed)
	 */
	application_type?: string;
}

export interface PhoneNumber {
	type: 'WORK' | 'HOME' | 'MOBILE';
	number: string;
}

export type PhoneNumbers = PhoneNumber[];

export interface PhoneNumbersUpdate {
	phone_numbers?: PhoneNumbers;
}

export interface PhoneNumbersData {
	data?: PhoneNumbersUpdate;
}

export interface Email {
	auth_code: string;
	email_address: string;
}

export interface EmailUpdate {
	data?: Email;
}

export interface Address {
	type: 'POSTAL' | 'RESIDENTIAL';
	address_line1: string;
	address_line2?: string;
	postcode: string;
	city: string;
	state: string;
	country: string;
	status: 'active' | 'inactive';
}

export type Addresses = Address[];

export interface AddressesUpdate {
	addresses?: Addresses;
}

export interface AddressesData {
	data?: AddressesUpdate;
}

export interface UpdatePhoneNumberData {
	/**
	 * RFC 3339 compliant date time 2017-07-21T17:32:28+10:00
	 */
	restriction_end_datetime?: string;
	restricted_applications?: string[];
}

/**
 * Successful phone number update (includes restriction date for mobile type)
 */
export interface UpdatePhoneNumberResponse {
	data?: UpdatePhoneNumberData;
}

export interface ProfileActionsResponse {
	review_your_personal_details?: boolean;
	add_your_mobile_number?: boolean;
	manage_your_beneficiaries?: boolean;
	communication_preference?: boolean;
	tell_your_employer_last_updated_date?: string;
	consolidate_your_super_last_updated_date?: string;
	make_contribution_last_updated_date?: string;
}

export interface ProfileActionsRequestData {
	data?: ProfileActionsRequestObject;
}

export interface ProfileActionsRequestObject {
	profile_actions?: ProfileActionsRequest;
}

export interface ProfileActionsRequest {
	review_your_personal_details?: boolean;
	communication_preference?: boolean;
	manage_your_beneficiaries?: boolean;
	tell_your_employer_last_updated_date?: string;
	consolidate_your_super_last_updated_date?: string;
	make_contribution_last_updated_date?: string;
}

/**
 * Update successful. No Content
 */
export type Response204 = void;

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Forbidden
 */
export type Response403 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Conflict
 */
export type Response409 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetMemberQueryParams {
	/**
	 * Flag for finegrain auth to exclude Link Data Response
	 */
	mode?: 'summary';
}

export interface GetMemberPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type GetMemberProps = Omit<
	GetProps<
		MemberData,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetMemberQueryParams,
		GetMemberPathParams
	>,
	'path'
> &
	GetMemberPathParams;

/**
 * Get member
 */
export const GetMember = ({ party_type_id, ...props }: GetMemberProps) => (
	<Get<
		MemberData,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetMemberQueryParams,
		GetMemberPathParams
	>
		path={`/members/${party_type_id}`}
		{...props}
	/>
);

export type UseGetMemberProps = Omit<
	UseGetProps<
		MemberData,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetMemberQueryParams,
		GetMemberPathParams
	>,
	'path'
> &
	GetMemberPathParams;

/**
 * Get member
 */
export const useGetMember = ({ party_type_id, ...props }: UseGetMemberProps) =>
	useGet<
		MemberData,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetMemberQueryParams,
		GetMemberPathParams
	>(
		(paramsInPath: GetMemberPathParams) =>
			`/members/${paramsInPath.party_type_id}`,
		{ pathParams: { party_type_id }, ...props },
	);

export interface UpdateEmailsPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type UpdateEmailsProps = Omit<
	MutateProps<
		Response204,
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		EmailUpdate,
		UpdateEmailsPathParams
	>,
	'path' | 'verb'
> &
	UpdateEmailsPathParams;

/**
 * Update member email
 */
export const UpdateEmails = ({
	party_type_id,
	...props
}: UpdateEmailsProps) => (
	<Mutate<
		Response204,
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		EmailUpdate,
		UpdateEmailsPathParams
	>
		verb="PUT"
		path={`/members/${party_type_id}/email`}
		{...props}
	/>
);

export type UseUpdateEmailsProps = Omit<
	UseMutateProps<
		Response204,
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		EmailUpdate,
		UpdateEmailsPathParams
	>,
	'path' | 'verb'
> &
	UpdateEmailsPathParams;

/**
 * Update member email
 */
export const useUpdateEmails = ({
	party_type_id,
	...props
}: UseUpdateEmailsProps) =>
	useMutate<
		Response204,
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		EmailUpdate,
		UpdateEmailsPathParams
	>(
		'PUT',
		(paramsInPath: UpdateEmailsPathParams) =>
			`/members/${paramsInPath.party_type_id}/email`,
		{ pathParams: { party_type_id }, ...props },
	);

export interface UpdatePhoneNumbersPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type UpdatePhoneNumbersProps = Omit<
	MutateProps<
		UpdatePhoneNumberResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		PhoneNumbersData,
		UpdatePhoneNumbersPathParams
	>,
	'path' | 'verb'
> &
	UpdatePhoneNumbersPathParams;

/**
 * Update member phone numbers
 */
export const UpdatePhoneNumbers = ({
	party_type_id,
	...props
}: UpdatePhoneNumbersProps) => (
	<Mutate<
		UpdatePhoneNumberResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		PhoneNumbersData,
		UpdatePhoneNumbersPathParams
	>
		verb="PUT"
		path={`/members/${party_type_id}/phone-numbers`}
		{...props}
	/>
);

export type UseUpdatePhoneNumbersProps = Omit<
	UseMutateProps<
		UpdatePhoneNumberResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		PhoneNumbersData,
		UpdatePhoneNumbersPathParams
	>,
	'path' | 'verb'
> &
	UpdatePhoneNumbersPathParams;

/**
 * Update member phone numbers
 */
export const useUpdatePhoneNumbers = ({
	party_type_id,
	...props
}: UseUpdatePhoneNumbersProps) =>
	useMutate<
		UpdatePhoneNumberResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		PhoneNumbersData,
		UpdatePhoneNumbersPathParams
	>(
		'PUT',
		(paramsInPath: UpdatePhoneNumbersPathParams) =>
			`/members/${paramsInPath.party_type_id}/phone-numbers`,
		{ pathParams: { party_type_id }, ...props },
	);

export interface UpdateAddressesPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type UpdateAddressesProps = Omit<
	MutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		AddressesData,
		UpdateAddressesPathParams
	>,
	'path' | 'verb'
> &
	UpdateAddressesPathParams;

/**
 * Update member address
 */
export const UpdateAddresses = ({
	party_type_id,
	...props
}: UpdateAddressesProps) => (
	<Mutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		AddressesData,
		UpdateAddressesPathParams
	>
		verb="PUT"
		path={`/members/${party_type_id}/addresses`}
		{...props}
	/>
);

export type UseUpdateAddressesProps = Omit<
	UseMutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		AddressesData,
		UpdateAddressesPathParams
	>,
	'path' | 'verb'
> &
	UpdateAddressesPathParams;

/**
 * Update member address
 */
export const useUpdateAddresses = ({
	party_type_id,
	...props
}: UseUpdateAddressesProps) =>
	useMutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		AddressesData,
		UpdateAddressesPathParams
	>(
		'PUT',
		(paramsInPath: UpdateAddressesPathParams) =>
			`/members/${paramsInPath.party_type_id}/addresses`,
		{ pathParams: { party_type_id }, ...props },
	);

export interface UpdateFirstWebPortalLoginPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type UpdateFirstWebPortalLoginProps = Omit<
	MutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		void,
		UpdateFirstWebPortalLoginPathParams
	>,
	'path' | 'verb'
> &
	UpdateFirstWebPortalLoginPathParams;

/**
 * Update the first_web_portal_login column in Member_Profiles DB after the member gone through the first time login
 */
export const UpdateFirstWebPortalLogin = ({
	party_type_id,
	...props
}: UpdateFirstWebPortalLoginProps) => (
	<Mutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		void,
		UpdateFirstWebPortalLoginPathParams
	>
		verb="PUT"
		path={`/members/${party_type_id}/first-web-portal-login`}
		{...props}
	/>
);

export type UseUpdateFirstWebPortalLoginProps = Omit<
	UseMutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		void,
		UpdateFirstWebPortalLoginPathParams
	>,
	'path' | 'verb'
> &
	UpdateFirstWebPortalLoginPathParams;

/**
 * Update the first_web_portal_login column in Member_Profiles DB after the member gone through the first time login
 */
export const useUpdateFirstWebPortalLogin = ({
	party_type_id,
	...props
}: UseUpdateFirstWebPortalLoginProps) =>
	useMutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		void,
		UpdateFirstWebPortalLoginPathParams
	>(
		'PUT',
		(paramsInPath: UpdateFirstWebPortalLoginPathParams) =>
			`/members/${paramsInPath.party_type_id}/first-web-portal-login`,
		{ pathParams: { party_type_id }, ...props },
	);

export interface UpdateProfileActionsPathParams {
	/**
	 * Unique ID assigned to the member at AustralianSuper. This is a hashed value
	 */
	party_type_id: string;
}

export type UpdateProfileActionsProps = Omit<
	MutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		ProfileActionsRequestData,
		UpdateProfileActionsPathParams
	>,
	'path' | 'verb'
> &
	UpdateProfileActionsPathParams;

/**
 * This endpoint will be used to update profile actions columns in Member_Profiles table of Members Database.
 */
export const UpdateProfileActions = ({
	party_type_id,
	...props
}: UpdateProfileActionsProps) => (
	<Mutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		ProfileActionsRequestData,
		UpdateProfileActionsPathParams
	>
		verb="PUT"
		path={`/members/${party_type_id}/profile-actions`}
		{...props}
	/>
);

export type UseUpdateProfileActionsProps = Omit<
	UseMutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		ProfileActionsRequestData,
		UpdateProfileActionsPathParams
	>,
	'path' | 'verb'
> &
	UpdateProfileActionsPathParams;

/**
 * This endpoint will be used to update profile actions columns in Member_Profiles table of Members Database.
 */
export const useUpdateProfileActions = ({
	party_type_id,
	...props
}: UseUpdateProfileActionsProps) =>
	useMutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		ProfileActionsRequestData,
		UpdateProfileActionsPathParams
	>(
		'PUT',
		(paramsInPath: UpdateProfileActionsPathParams) =>
			`/members/${paramsInPath.party_type_id}/profile-actions`,
		{ pathParams: { party_type_id }, ...props },
	);

export const UpdateProfileActionsTypes = {
	REVIEW_YOUR_PERSONAL_DETAILS: 'review_your_personal_details',
	COMMUNICATION_PREFERENCE: 'communication_preference',
	MANAGE_YOUR_BENEFICIARIES: 'manage_your_beneficiaries',
	CONSOLIDATE_YOUR_SUPER: 'consolidate_your_super_last_updated_date',
	MAKE_CONTRIBUTION: 'make_contribution_last_updated_date',
	TELL_YOUR_EMPLOYER: 'tell_your_employer_last_updated_date',
	ADD_YOUR_MOBILE_NUMBER: 'add_your_mobile_number',
};

export type UpdateProfileActionsValues = {
	data: {
		profile_actions: {
			review_your_personal_details?: boolean;
			communication_preference?: boolean;
			tell_your_employer_last_updated_date?: string;
			consolidate_your_super_last_updated_date?: string;
			make_contribution_last_updated_date?: string;
			add_your_mobile_number?: boolean;
		};
	};
};

import React from 'react';
import Helmet from 'react-helmet';
import { animateScroll } from 'react-scroll';
import isEmpty from 'lodash/isEmpty';
import {
	isExperienceEditorActive,
	dataApi,
} from '@sitecore-jss/sitecore-jss-react';

import { AI } from 'core/services/AppInsights';
import { LOGOUT_STATUS, SITECORE_PAGES } from 'core/constants';
import {
	getAccessToken,
	setLastUrlAccess,
	getDisplayLogoutSuccessFlag,
	setDisplayLogoutSuccessFlag,
	getIsFirstSitecoreLayout,
	setIsFirstSitecoreLayout,
	getIsFirstPageLoad,
	setIsFirstPageLoad,
	getAccountSwitchSplash,
	setAccountSwitchSplash,
	clearSessionCookie,
	clearLocalStorage,
	getIsAccountSwitchedFromDb,
	setIsAccountSwitchedFromDb,
} from 'core/browserStorage';
import { isWebBrowser, getCurrentUrlHostname } from 'core/utils/global-utils';
import { memberLogout } from 'core/api/sitecore.api';
import { doRefreshToken } from 'core/utils/api-utils';

import LoadingLayout from './LoadingLayout/LoadingLayout';

import SitecoreContextFactory from 'jss-boilerplate/SitecoreContextFactory';
import { dataFetcher } from 'jss-boilerplate/dataFetcher';
import config from 'jss-boilerplate/temp/config';
import Layout from 'jss-boilerplate/Layout';
import NotFound from 'jss-boilerplate/NotFound';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let ssrInitialState = null;

const JssImageTypeDefault = {
	value: {
		src: '',
		alt: '',
		class: '',
		title: '',
	},
};

async function triggerTokenRefresh() {
	return doRefreshToken()
		.then(function(result) {
			// Return the result of the doRefreshToken function
			return result;
		})
		.catch(function(error) {
			// Handle errors if necessary
			throw error; // Propagate the error
		});
}

function loggedOutMember() {
	memberLogout()
		.then(result => {
			if (result.status === 200) {
				const accountSwitchSplash = getAccountSwitchSplash();
				// clear member session from browser and redirect to login page
				sessionStorage.clear();
				clearLocalStorage();
				clearSessionCookie();
				if (!!accountSwitchSplash) {
					setAccountSwitchSplash(accountSwitchSplash);
				}
				setDisplayLogoutSuccessFlag(LOGOUT_STATUS.LOGOUT);
				window.location.assign(SITECORE_PAGES.LOGIN);
				return;
			}
		})
		.catch(error => {
			console.log('[Resilience] - Error in logging out: ', error);
		});
}

export default class RouteHandler extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			notFound: true,
			routeData: ssrInitialState, // null when client-side rendering
			defaultLanguage: config.defaultLanguage,
			isFetching: false,
		};

		if (
			ssrInitialState &&
			ssrInitialState.sitecore &&
			ssrInitialState.sitecore.route
		) {
			// set the initial sitecore context data if we got SSR initial state
			SitecoreContextFactory.setSitecoreContext({
				route: ssrInitialState.sitecore.route,
				itemId: ssrInitialState.sitecore.route.itemId,
				...ssrInitialState.sitecore.context,
			});
		}

		// route data from react-router - if route was resolved, it's not a 404
		if (props.route !== null) {
			this.state.notFound = false;
		}

		// if we have an initial SSR state, and that state doesn't have a valid route data,
		// then this is a 404 route.
		if (
			ssrInitialState &&
			(!ssrInitialState.sitecore || !ssrInitialState.sitecore.route)
		) {
			this.state.notFound = true;
		}

		// if we have an SSR state, and that state has language data, set the current language
		// (this makes the language of content follow the Sitecore context language cookie)
		// note that a route-based language (i.e. /de-DE) will override this default; this is for home.
		if (
			ssrInitialState &&
			ssrInitialState.context &&
			ssrInitialState.context.language
		) {
			this.state.defaultLanguage = ssrInitialState.context.language;
		}

		// once we initialize the route handler, we've "used up" the SSR data,
		// if it existed, so we want to clear it now that it's in react state.
		// future route changes that might destroy/remount this component should ignore any SSR data.
		// EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
		// (once to find GraphQL queries that need to run, the second time to refresh the view with
		// GraphQL query results)
		// We test for SSR by checking for Node-specific process.env variable.
		if (isWebBrowser) {
			ssrInitialState = null;
		}

		this.componentIsMounted = false;
		this.languageIsChanging = false;
		this.loadingLayoutLogoSrc = JssImageTypeDefault;
		this.loadingLayoutFooterProps = null;
	}

	componentDidMount() {
		triggerTokenRefresh();

		// if no existing routeData is present (from SSR), get Layout Service fetching the route data
		if (!this.state.routeData) {
			doRefreshToken();
			this.updateRouteData();
		}

		this.componentIsMounted = true;

		// Start of Loading Layout Props
		const jssTop =
			SitecoreContextFactory?.context?.route?.placeholders?.['jss-top'];
		const jssBottom =
			SitecoreContextFactory?.context?.route?.placeholders?.['jss-bottom'];
		const headerField =
			Array.isArray(jssTop) && jssTop?.length > 0
				? jssTop?.find(child => child?.componentName === 'Header')
				: null;
		const footerField =
			Array.isArray(jssBottom) && jssBottom?.length > 0
				? jssBottom?.find(child => child?.componentName === 'Footer')
				: null;

		this.loadingLayoutLogoSrc =
			headerField?.fields?.headerLogo ?? JssImageTypeDefault;
		this.loadingLayoutFooterProps = footerField?.fields ?? null;
		// End of Loading Layout Props
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	/**
	 * Loads route data from Sitecore Layout Service into state.routeData
	 */
	updateRouteData() {
		this.setState({ isFetching: true });
		let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
		if (!sitecoreRoutePath.startsWith('/')) {
			sitecoreRoutePath = `/${sitecoreRoutePath}`;
		}

		const language =
			this.props.route.match.params.lang || this.state.defaultLanguage;
		// get the route data for the new route
		getRouteData(sitecoreRoutePath, language).then(routeData => {
			if (
				routeData !== null &&
				routeData.sitecore &&
				routeData.sitecore.route
			) {
				if (
					routeData?.sitecore?.context?.maintenance?.maintenanceFlag === true
				) {
					// maintenanceFlag is true, means we need to forced the user to be logged out.
					loggedOutMember();
				} else {
					// set the sitecore context data and push the new route
					SitecoreContextFactory.setSitecoreContext({
						route: routeData.sitecore.route,
						itemId: routeData.sitecore.route.itemId,
						...routeData.sitecore.context,
					});
					this.setState({ routeData, notFound: false });
					// scroll to top after successful page navigation
					animateScroll.scrollToTop();

					// log this route change as a page view to App Insights
					AI.trackPageViewPerformance({
						name: routeData.sitecore.route.name,
						url: sitecoreRoutePath,
					});
				}
			} else if (routeData === '401' && isWebBrowser) {
				// If 'idle' it will redirect to /personal/logout hence
				// we need to exclude it since the last URL will always be /personal/logout
				// setting last URL for 'idle' is done through react-idle-timer
				if (getDisplayLogoutSuccessFlag() !== LOGOUT_STATUS.IDLE) {
					// Preserved the last url access by the user
					setLastUrlAccess(sitecoreRoutePath);
				}
				// Clear session storage
				sessionStorage.clear();
				clearLocalStorage();
				clearSessionCookie();
				// Redirect to login page
				window.location.assign(SITECORE_PAGES.LOGIN);
			} else {
				this.setState({ routeData, notFound: true, isFetching: false });

				// log page/route not found as a custom event to App Insights
				AI.trackEvent({
					name: 'sitecore.route.notfound',
					value: sitecoreRoutePath,
				});
			}

			this.setState({ isFetching: false });
		});
	}

	componentDidUpdate(previousProps) {
		const existingRoute = previousProps.route.match.url;
		const newRoute = this.props.route.match.url;

		// check for logout request
		if (newRoute.toLowerCase() === SITECORE_PAGES.LOGOUT) {
			loggedOutMember();

			return;
		}

		// don't change state (refetch route data) if the route has not changed
		if (existingRoute === newRoute) {
			if (getIsAccountSwitchedFromDb() === 'true') {
				setIsAccountSwitchedFromDb('false');
				setIsFirstPageLoad('false');
				doRefreshToken();
				this.updateRouteData();
				return;
			} else {
				return;
			}
		}

		// if in experience editor - force reload instead of route data update
		// avoids confusing Sitecore's editing JS
		if (
			isExperienceEditorActive() ||
			ssrInitialState?.sitecore?.route?.databaseName === 'master'
		) {
			window.location.assign(newRoute);

			return;
		}

		// For Skeleton Loader to check if the skeleton loader should be used again
		if (
			existingRoute === '/' &&
			newRoute !== '/' &&
			getIsFirstPageLoad() === ''
		) {
			setIsFirstPageLoad('false');
		}
		triggerTokenRefresh();
		this.updateRouteData();
	}

	render() {
		const { notFound, routeData, isFetching } = this.state;
		const accessToken = getAccessToken();
		const isUnauthenticated =
			routeData?.sitecore?.route?.fields?.authenticatedPage?.value ?? false;
		// This redirect user to login page if they go directly to any page (requires login) and not yet authenticated.

		if (
			!isEmpty(accessToken) &&
			isUnauthenticated === true &&
			routeData === '302' &&
			routeData?.sitecore?.context?.maintenance?.maintenanceFlag === true
		) {
			// maintenanceFlag is true, means we need to forced the user to be logged out.
			loggedOutMember();
			return;
		}

		if (
			isEmpty(accessToken) &&
			isUnauthenticated === true &&
			isWebBrowser &&
			!isExperienceEditorActive() &&
			routeData?.sitecore?.route?.databaseName !== 'master'
		) {
			window.location.assign(SITECORE_PAGES.LOGIN);
			return;
		}

		// This redirects the user on the `/unauthorized' route
		// if they met the conditions below.
		if (
			!isEmpty(accessToken) &&
			routeData === '401' &&
			isUnauthenticated === true &&
			isWebBrowser &&
			!isExperienceEditorActive()
		) {
			window.location.assign(SITECORE_PAGES.UNAUTHORIZED);
		}

		// This redirects the user on the ERR 500 page/route
		// if we received 500 code from Sitecore.
		if (
			isEmpty(accessToken) &&
			routeData === '500' &&
			isUnauthenticated === true &&
			isWebBrowser &&
			!isExperienceEditorActive()
		) {
			window.location.assign(SITECORE_PAGES.SOMETHING_WENT_WRONG);
		}

		// no route data for the current route in Sitecore - show not found component.
		// Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
		// of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
		if (notFound) {
			return (
				<div>
					<Helmet>
						<title>Page not found</title>
					</Helmet>
					<NotFound
						context={
							routeData && routeData.sitecore && routeData.sitecore.context
						}
					/>
				</div>
			);
		}

		// Don't render anything if the route data or dictionary data is not fully loaded yet.
		// This is a good place for a "Loading" component, if one is needed.
		// if (!routeData || this.languageIsChanging) {
		// 	return null;
		// }

		if (
			isFetching &&
			(getIsFirstSitecoreLayout() === null || getIsFirstSitecoreLayout() === '')
		) {
			// Note: Needs localStorage checking since this will trigger every Sitecore--
			// --Layout API change (every route change)

			// Any value here as long as it is not the initial state or null/''
			setIsFirstSitecoreLayout('false');
			return (
				<LoadingLayout
					headerLogo={this.loadingLayoutLogoSrc}
					footerProps={this.loadingLayoutFooterProps}
				/>
			);
		}

		// Render the app's root structural layout
		return (
			<Layout
				context={routeData.sitecore.context}
				siteSettings={routeData.sitecore.siteSettings}
				route={routeData.sitecore.route}
			/>
		);
	}
}

/**
 * Sets the initial state provided by server-side rendering.
 * Setting this state will bypass initial route data fetch calls.
 * @param {Object} ssrState
 */
export function setServerSideRenderingState(ssrState) {
	ssrInitialState = ssrState;
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route - Route path to get data for (e.g. /about)
 * @param {string} language - Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
	const fetchOptions = {
		layoutServiceConfig: {
			host: getCurrentUrlHostname,
		},
		querystringParams: {
			sc_lang: language,
			sc_apikey: config.sitecoreApiKey,
		},
		fetcher: dataFetcher,
	};

	triggerTokenRefresh();
	return dataApi.fetchRouteData(route, fetchOptions).catch(error => {
		if (
			error.response &&
			error.response.status === 404 &&
			error.response.data
		) {
			return error.response.data;
		}

		if (
			error.response &&
			error.response.status === 401 &&
			error.response.data
		) {
			return '401';
		}

		if (
			error.response &&
			error.response.status === 302 &&
			error.response.data
		) {
			return '302';
		}

		return null;
	});
}

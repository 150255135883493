export enum KEYBOARD_EVENT_KEYS {
	SPACEBAR = ' ',
	ENTER = 'Enter',
	ESC = 27,
}

export const QUOTE_TYPE = {
	ROLLOVER: {
		value: 'ROLLOVER',
		label: 'Moving to another super fund',
	},
	RETIREMENT: {
		value: 'RETIREMENT',
		label: 'Retiring',
	},
};

export enum PAYMENTS_PRODUCT_NAME {
	PENSION = 'Pension',
	ACCUMULATION = 'Accumulation',
}

export enum PENDING_PROFILE {
	CHOICE_INCOME = 'Choice Income',
	ACCUMULATION = 'Accumulation',
}

export enum PENDING_STATUS {
	ACTIVE = 'active',
	PROVISIONAL = 'provisional',
}

export enum PRODUCT_NAME_ALIAS {
	ACCUMULATION = 'Super',
	PENSION = 'Choice Income',
	TTR = 'TTR Income',
}

export enum MEMBER_STATUS {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	PENDING = 'pending',
	CREATED = 'created',
}

export enum ACCOUNT_STATUS {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	PENDING = 'PENDING',
	PROVISIONAL = 'PROVISIONAL',
	HOLDING = 'HOLDING',
}

export enum REGISTRATION_STATUS {
	INCOMPLETE = 'INCOMPLETE',
	ACTIVE = 'ACTIVE',
	LOCKED = 'LOCKED',
}
export enum LOGOUT_STATUS {
	LOGOUT = 'logout',
	IDLE = 'idle',
	LOGOUT_JWT = 'logout-jwt',
}

export enum PHONE_NUMBERS_TYPE {
	WORK = 'WORK',
	HOME = 'HOME',
	FAX = 'FAX',
	MOBILE = 'MOBILE',
}

export enum MODAL_ARIA_NAMES {
	HEADING = 'modal-heading',
	DESCRIPTION = 'modal-description',
}

export enum MODAL_CANCEL_ARIA_NAMES {
	HEADING = 'modal-cancel-heading',
	DESCRIPTION = 'modal-cancel-description',
}

export type TTR_CLASSIFICATION_TYPE = 'TTR' | undefined;

export const NON_NUMBER_CHAR_ARRAY = ['e', 'E', '+', '-'];

export const QUALIFIED_NAME = 'download';

export const SITECORE_PAGE_MODE = ['preview', 'edit'];

export const ZERO_WITH_DECIMALS = '0.00';

export const NAN = '$NaN';

export const MEMBER_DIRECT_SSO_URL_TEMPLATE =
	'<%- baseUrl %>?product_name=<%- productName %>&account_number=<%- accountNumber %>';

export const WITHDRAWAL_FULL_BALANCE = '$ 0';

export const NO_PREVIOUS_LOGIN = 'No previous login';

export const DEFAULT_TIMEZONE_LABEL = 'AEST/AEDT';

export const LOGIN_UUID_KEY = 'deviceID';

export const MOBILE_BP = '0,s';

import { getSessionStorageItem, setSessionStorageItem } from './sessionStorage';

const PROFILE_ACTIONS_KEY = 'ProfileActions';

/***
 * Convert the Data Value into string and store it in session storage
 */
export const setSessionProfileActions = (ObjectDataValue: object) => {
	setSessionStorageItem(PROFILE_ACTIONS_KEY, JSON.stringify(ObjectDataValue));
};

/***
 * Retrieval of  data from the session storage
 */
export const getSessionProfileActions = () => {
	const retrievedData = getSessionStorageItem(PROFILE_ACTIONS_KEY);
	if (retrievedData) {
		return retrievedData;
	} else {
		return '';
	}
};

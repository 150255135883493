/* eslint-disable @typescript-eslint/camelcase */

import { format } from 'date-fns';
import config from 'jss-boilerplate/temp/config.js';
import { useMemberContext } from 'core/hooks';

import api from './api';
import {
	UpdateProfileActionsTypes,
	UpdateProfileActionsValues,
} from './useUpdateProfileActions.types';

const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathMember}`;

export function useUpdateProfileActionsHook() {
	const memberContext = useMemberContext() ?? {};

	const { mutate: updateProfileActionsRequest } = api.useUpdateProfileActions({
		base,
		party_type_id: memberContext.party_type_id ?? '',
	});

	const updateProfileActions = async (updateType: string) => {
		const profileActionsPayload: UpdateProfileActionsValues = {
			data: { profile_actions: {} },
		};

		if (
			updateType === UpdateProfileActionsTypes.CONSOLIDATE_YOUR_SUPER ||
			updateType === UpdateProfileActionsTypes.TELL_YOUR_EMPLOYER ||
			updateType === UpdateProfileActionsTypes.MAKE_CONTRIBUTION
		) {
			const currDateString = format(new Date(), 'yyyy-MM-dd');
			profileActionsPayload.data.profile_actions[updateType] = currDateString;
		} else {
			profileActionsPayload.data.profile_actions[updateType] = true;
		}

		try {
			await updateProfileActionsRequest(profileActionsPayload);
		} catch (e) {
			console.error('Error in update profile actions');
		}
	};

	return {
		updateProfileActions,
	};
}

import styled, { css } from 'styled-components';

import * as mx from 'core/styles/mixins';
import * as vr from 'core/styles/variables';

const alignPanel = (alignment: string) => {
	switch (alignment) {
		case 'right':
			return css`
				right: 0;
				::before {
					right: 20%;
				}
			`;
		case 'left':
			return css`
				left: 0;

				::before {
					left: 20%;
				}
			`;
		case 'plain-right-justified':
			return css`
				left: 0;
				right: 50%;

				::before {
					left: 0;
					right: 50%;
				}
			`;
		case 'center':
		default:
			return css`
				left: 50%;
				right: 50%;
				transform: translate(-50%, 0);

				::before {
					left: 50%;
					right: 50%;
				}
			`;
	}
};

export const Wrapper = styled.div<{
	isExpanded?: boolean;
	topOffset?: number;
	alignment: 'right' | 'left' | 'center' | 'plain-right-justified';
	isShowTriangleOnTop?: boolean;
}>`
	/* Place above the sticky header */
	${mx.zIndex('middle', 1)}
	${mx.listReset}

	background-color: ${vr.brandWhite};
	display: ${({ isExpanded }) => (isExpanded ? 'table' : 'none')};
	position: absolute;
	
	${({ isShowTriangleOnTop }) =>
		(isShowTriangleOnTop &&
			css`
				border-radius: 8px;
				box-shadow: 0 4px 5px 5px rgba(0, 0, 0, 0.2);
			`) ||
		css`
			border-radius: 2px 2px 8px 8px;
			box-shadow: ${({ theme }) =>
					theme.global.page.authenticatedHeader.primary.desktop
						.dropdownShadowColor}
				0 1px 1px 1px;
		`}

	top: ${({ topOffset }) => `${topOffset ?? 55}px`};;
	margin: 0;
	padding: 4px 0;

	/* Triangle on top of the dropdown with shadow */
	::before {

		${({ isShowTriangleOnTop }) =>
			isShowTriangleOnTop &&
			css`
				${mx.pseudo}
				width: 0;
				height: 0;
				top: 0;
				transform-origin: 0 0;
				transform: rotate(-45deg) translate(-50%, -50%);

				border: 5px solid black;
				border-color: ${vr.brandWhite} ${vr.brandWhite} transparent transparent;
				box-shadow: 1px -1px 1px 1px rgba(0, 0, 0, 0.05);
			`}

	}



	${({ alignment }) => alignPanel(alignment)};
`;

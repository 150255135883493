import styled, { css } from 'styled-components';

import * as mx from 'core/styles/mixins';

export const StickyContainer = styled.div<{
	isFixedOnTop?: boolean;
	elementHeight?: number;
	fallbackHeight: number;
	showOrangeBar?: boolean;
}>`
	${mx.zIndex('middle')}

	${props =>
		props.isFixedOnTop &&
		css`
			& {
				margin: 0;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
			}
		`}

	${props =>
		props.showOrangeBar &&
		props.isFixedOnTop &&
		css`
			& {
				border-top: 4px solid
					${({ theme }) => theme.global.colors.primary.color};
			}
		`}
	
	width: 100%;
`;

import { LOGIN_UUID_KEY } from 'core/constants';
import { isWebBrowser } from 'core/utils/global-utils';

export const getLocalStorageItem = (key: string): string => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return '';
	}

	const itemValue = window?.localStorage?.getItem(key) ?? '';

	return itemValue;
};

export const setLocalStorageItem = (key: string, itemValue: string) => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return;
	}
	window?.localStorage?.setItem(key, itemValue);
};

export const removeLocalStorageItem = (key: string) => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return;
	}
	window?.localStorage?.removeItem(key);
};

export const clearLocalStorage = () => {
	// Wrapper for localStorage.clear(). Clears everything EXCEPT keys
	// listed below
	const exemptedKeys = [LOGIN_UUID_KEY];
	interface StorageKeyPair {
		key: string;
		data: string;
	}

	const exemptData: StorageKeyPair[] = [];

	// Store exempted keys separately
	exemptedKeys.map(key => {
		const tempData = getLocalStorageItem(key);
		exemptData.push({ key: key, data: tempData });
	});

	window?.localStorage?.clear();

	// Put all exempted keys and data back to localStorage
	exemptData.map((value: StorageKeyPair) => {
		setLocalStorageItem(value.key, value.data);
	});
};

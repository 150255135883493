/* eslint-disable @typescript-eslint/camelcase */
import { GetDataError } from 'restful-react';
import { isStorybook } from 'stories/utils';

import config from 'jss-boilerplate/temp/config.js';

import {
	useMemberContext,
	getSelectedMemberAccount,
} from 'core/sitecore/member';
import { PRODUCT_NAME } from 'core/constants';
import { convertDate, convertNonUtcDate } from 'core/utils/date-utils';
import {
	useAppContext,
	useGetPaymentFrequencies,
	useGetPaymentTypes,
} from 'core/hooks';
import { Error as PaymentError } from 'core/api/client/PaymentApi';
import { getFormattedCurrentFY } from 'jss-components/t06-dashboard/helpers/get-financial-year';
import GetFinancialYearsListReferenceMock from 'core/hooks/useGetReferences/api/mocks/GetFinancialYearsListReference.mock.json';

import api from './api';
import { parsePaymentResponse } from './usePaymentSummary.helpers';

const paymentBase = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathPayment}`;

/**
 * A hook to handle the Payment API response.
 * @description Handle the loading and error state of get payment summary api call.
 *   With the fetched response:
 *     - find the past payments (previousPayments) and sorted by the paid date (most recent at the start)
 *     - find the information about the upcoming payment: frequency, due date, and amount
 *     - **if no upcoming payment found, `nextPaymentInfo` set to `null`**
 *   Without fetched response: all data related the payment summary is not return (no default values)
 * @note API Rules:
 *   - the order of the payment_calendar items is depends on the link api response, so it is safer to sort the array by payment date.
 */
export const usePaymentSummary = (errMessage?: string) => {
	const { accounts = [] } = useMemberContext();
	const { fyResponseData } = useAppContext();

	const selectedAccount = getSelectedMemberAccount(accounts);
	const accountNumber = selectedAccount?.account_number ?? '';
	const productName = selectedAccount?.product_name ?? '';

	const currentFYObj = getFormattedCurrentFY(
		isStorybook()
			? GetFinancialYearsListReferenceMock?.data?.values
			: fyResponseData?.data?.values,
	);

	const currentFYFromDate = convertNonUtcDate(
		currentFYObj?.from_date ?? new Date(),
		'yyyy-MM-dd',
	);
	const currentFYToDate = convertNonUtcDate(
		currentFYObj?.to_date ?? new Date(),
		'yyyy-MM-dd',
	);

	const {
		data: apiDataPaymentSummary,
		loading: loadingPaymentSummary,
		error: errorPaymentSummary,
		refetch: refetchPaymentSummary,
	} = api.useGetPaymentSummary({
		base: paymentBase,
		product_name: productName as PRODUCT_NAME.PENSION,
		account_number: accountNumber as string,
		queryParams: {
			from_date: currentFYFromDate,
			to_date: currentFYToDate,
		} as any,
	});
	// Payment Frequencies - Reference Data API
	const {
		paymentFrequencyRef,
		loadingPaymentFrequencyRef,
		errorPaymentFrequencyRef,
		refetchPaymentFrequencyRef,
	} = useGetPaymentFrequencies();

	//Payment Type Reference
	const {
		loadingPaymentTypesRef,
		errorPaymentTypesRef,
		refetchPaymentTypesRef,
		paymentTypesRef,
	} = useGetPaymentTypes();

	const isLoading =
		loadingPaymentSummary ||
		loadingPaymentFrequencyRef ||
		loadingPaymentTypesRef;

	const currentFYObjError: GetDataError<PaymentError> | null =
		typeof currentFYObj === 'undefined'
			? { status: 500, data: '', message: '' }
			: null;
	const error =
		errorPaymentSummary ??
		errorPaymentFrequencyRef ??
		currentFYObjError ??
		errorPaymentTypesRef;

	const isDataReady =
		!isLoading &&
		!error &&
		!!apiDataPaymentSummary?.data?.pension_details?.length;
	// parse the response and set default values if data is partially returned or not ready
	const {
		totalPayments = 0,
		nextPaymentInfo = null,
		previousPayments = [],
		isPaymentCompleteYtd = false,
		paymentType = '',
		paymentIndexFactor = '',
		paymentDay = 0,
		amountPerPayment = 0,
		paymentTypeDesc = '',
		paymentCalendarTotalPaid = 0,
	} = isDataReady
		? parsePaymentResponse(
				apiDataPaymentSummary?.data?.pension_details ?? [],
				paymentFrequencyRef,
				paymentTypesRef,
		  )
		: {};

	const accountBasedDetails = apiDataPaymentSummary?.data?.pension_details?.map(
		acct => {
			return (
				acct.account_based_details ?? {
					minimum_percentage: 0,
					maximum_percentage: 0,
					minimum_payable: 0,
					maximum_payable: 0,
				}
			);
		},
	);

	const paymentDetails = {
		loadingPaymentSummary,
		errorPaymentSummary,
		refetchPaymentSummary,
		paymentFrequency: nextPaymentInfo?.paymentFrequency?.toUpperCase(),
		paymentFrequencyDesc: nextPaymentInfo?.paymentFrequencyDesc,
		paymentType,
		amountPerPayment: amountPerPayment,
		paymentDay,
		paymentMonth: convertDate(
			nextPaymentInfo?.paymentPayableDate ?? '',
			'MMMM',
		),
		paymentIndexFactor,
		accountBasedDetails,
		paymentTypeDesc,
		paymentCalendarTotalPaid,
	};

	const refetch = () => {
		if (errorPaymentSummary) {
			refetchPaymentSummary();
		}

		if (errorPaymentFrequencyRef) {
			refetchPaymentFrequencyRef();
		}

		if (errorPaymentTypesRef) {
			refetchPaymentTypesRef();
		}
	};

	return {
		error,
		refetch,
		isLoading,
		totalPayments,
		nextPaymentInfo,
		previousPayments,
		moduleErrorMessage: errMessage,
		isPaymentCompleteYtd,
		paymentDetails,
		accountBasedDetails,
	};
};
